import './src/assets/css/global.scss'

export const onClientEntry = () => {
  if (
    typeof window !== 'undefined' &&
    window.location.hostname === 'production.d2g6do5ux8vi9r.amplifyapp.com'
  ) {
    const newPath =
      window.location.pathname + window.location.search + window.location.hash
    window.location.replace(`https://www.avidaland.com${newPath}`)
  }
}
